$smartdeal-maxw: 1090px;
$smartdeal-radius: 20px;
$smartdeal-radius-small: 15px;
$smartdeal-gridunit: 36px;
$smartdeal-purple: #4e54a3;

.smartdeal {
  &-logo { display: block; width: 234px; margin: $gridunit-large auto 0; }
  &-button {
    display: block; width: 250px; text-decoration: none !important; margin: $smartdeal-gridunit auto 0; box-sizing: border-box; padding: $gridunit/2 $gridunit; text-align: center; border-radius: $base-radius; font-size: 18px;
    &:hover { text-decoration: none; }
    &.intent-primary { background-color: $color-primary; color: $color-text-light; }
    @include mobile { width: 100%; max-width: 250px; }
  }
  &-limiter {
    max-width: $smartdeal-maxw; margin: $gridunit-large auto 0; padding: 0 $gridunit;
  }
  &-tabs {
    display: flex; flex-wrap: wrap; justify-content: center; margin: $gridunit-large*1.5 0 0;
    overflow: hidden;
    &__option {
      position: absolute; left: -10000px;
      &:checked {
        &[data-value="iphone"] ~ [data-value="iphone"] { display: block; }
        &[data-value="mac"] ~ [data-value="mac"] { display: block; }
        &[data-value="ipad"] ~ [data-value="ipad"] { display: block; }
        + LABEL { background-color: $color-black; color: $color-text-light; }
      }
    }
    &__label {
      margin: $gridunit-small/2 $gridunit-large/2; padding: $gridunit-small $gridunit; border: 1px solid $color-black; border-radius: $gridunit-large; font-size: $font-size-larger;
    }
    &__content {
      flex: 0 0 100%; max-width: 100%; box-sizing: border-box; display: none; background-color: $color-gray-lighter02; margin: $gridunit-large*2 0 0; padding: $gridunit;
      H2 {
        text-align: center; margin: $gridunit-large*1.5 auto 0; padding: 0 $gridunit; font-weight: $bold; line-height: 1.4; font-size: $font-size-largest02;
        &.type02 { font-size: 30px; padding-bottom: $gridunit-large; }
        @include mobile { font-size: $font-size-larger; }
      }
    }
  }
  &-periods {
    max-width: $smartdeal-maxw; margin: $gridunit-large auto 0; display: flex; counter-reset: period; background-color: $color-gray-light03; border-radius: $smartdeal-radius-small;
    @include mobile { flex-direction: column; }
  }
  &-period {
    border-radius: $smartdeal-radius-small; flex: 1; padding: $gridunit $gridunit $gridunit*2; min-width: 33.3333%; box-sizing: border-box; text-align: center;
    &.primary { flex-basis: 66.666%; background-color: $smartdeal-purple; color: $color-text-light; }
    > P { font-weight: $bold; margin: 0; }
    OL {
      display: flex; list-style: none; padding: 0; margin: $gridunit-large 0 0;
      @include mobile { flex-direction: column; }
      > LI {
        flex: 1; font-size: 17px;
        &:before {
          counter-increment: period; content: counter(period); font-size: 30px; font-weight: $bold; width: 50px; height: 50px; margin: 0 auto $gridunit-large;
          border: 1px solid currentColor; border-radius: 50%; box-sizing: border-box; text-align: center;
          display: flex; align-items: center; justify-content: center;
        }
        @include mobile {
          + LI { margin-top: $gridunit-large; }
        }
      }
    }
  }
  &-section {
    background-color: $color-white; border-radius: $smartdeal-radius; max-width: $smartdeal-maxw; margin: $gridunit auto 0; display: flex; flex-wrap: wrap; justify-content: space-between;
    padding: 0 $gridunit-large*1.5; box-sizing: border-box; overflow: hidden;
    @include mobile { padding: 0 $gridunit $gridunit-large; }
    &__toggler {
      position: absolute; left: -10000px;
      + LABEL {
        order: 2; display: flex; align-items: center; align-self: flex-start; margin: $gridunit; cursor: pointer;
        &:hover { opacity: 0.6; }
        &:after {
          width: 40px; height: 40px; border-radius: 50%; margin-left: $gridunit-small; display: flex; justify-content: center; align-items: center; content: "+"; font-size: 45px;  border: 1px solid  $color-gray-medium-light;
        }
        .label-open { display: none; }
        @include mobile { margin: $gridunit 0 0; flex: 0 0 100%; }
        &.is-style-button {
          flex: 0 0 50%; flex-direction: column; align-items: flex-start; font-size: 28px; font-weight: 300; padding: 50px 0; max-width: 400px; align-self: center; pointer-events: none;
          cursor: default;
          &:hover {
            opacity: 1;
          }
          @include mobile { flex: 0 0 100%; padding-top: 0; }
          .smartdeal-section__label-extra { pointer-events: none; }
          .label {
            cursor: pointer; pointer-events: all; border: 1px solid #91bcec; padding: $gridunit*1.3 $gridunit; min-width: 225px; text-align: center; border-radius: $smartdeal-radius*1.5; font-weight: $bold; font-size: 20px; margin: $gridunit-large 0 0;
            &:hover { opacity: 0.9; }
            @include mobile { min-width: 100%; box-sizing: border-box; }
          }
          &:after { display: none; }
        }
      }
      ~ .smartdeal-section__content { display: none; flex: 0 0 100%; max-width: 100%; }
      &:checked {
        ~ .smartdeal-section__content {
          display: block;
          @include mobile { margin-top: $gridunit-large; }
        }
        + LABEL {
          .label-open { display: block; }
          .label-closed { display: none; }
          flex: 0 0 100%; justify-content: flex-end;
          &:after { content: "–"}
          .smartdeal-section__label-extra { display: none; }
        }
        ~ .smartdeal-section__header {
          .smartdeal-section__extra { display: none; }
          .smartdeal-section__header-text.has-icon {
            @include not-mobile {
              flex-direction: row; justify-content: center; align-items: center;
              H3 { margin: 0; }
              IMG { margin-right: 50px; }
            }
          }
        }
      }
    }
    &__header {
      flex: 1; display: flex; max-width: 100%;
      & &-illustration {
        max-width: 50%; width: 360px; align-self: flex-end; margin: $gridunit-large $gridunit 0;
        &.align-right { order: 2; }
      }
      &-text {
        padding: $gridunit; font-size: 20px; line-height: 1.5; flex: 0 1 100%;
        H2 { padding: 0; }
        H3 { font-size: 30px; font-weight: $bold; }
        &.align-center { text-align: center; }
        &.has-icon {
          padding: 50px $gridunit-large; display: flex; flex-direction: column; align-items: center; align-self: center; text-align: center;
          IMG { max-width: 100px; height: auto; }
          @include mobile {
            padding: 50px 0 0;
            H3 { margin: $gridunit 0 0; }
          }
        }
        @include mobile { padding: 0; }
      }
      @include mobile {
        flex-direction: column;
        & &-illustration { max-width: 100%; margin: $gridunit-large 0 0; }
      }
    }
  }
  &-steps {
    counter-reset: step;
    &__item {
      background-color: $color-gray-lighter02; overflow: hidden; border-radius: $base-radius*2; padding: $smartdeal-gridunit*1.2 $smartdeal-gridunit $smartdeal-gridunit 0; font-size: 18px; line-height: 1.4;
      @include clearfix;
      display: flex;
      @include mobile { flex-direction: column; padding: $gridunit; }
      + .smartdeal-steps__item { margin-top: $gridunit; }
      &-inner {
        position: relative; padding-left: $smartdeal-gridunit*3; flex: 0 1 100%;
        &:before {
          counter-increment: step; content: counter(step); font-size: 30px; font-weight: $bold; width: 50px; height: 50px; margin: 0 auto $gridunit-large;
          border: 1px solid $color-gray-medium-light; border-radius: 50%; box-sizing: border-box; text-align: center;
          display: flex; align-items: center; justify-content: center; position: absolute; left: $smartdeal-gridunit; top: -10px;
        }
        @include mobile {
          padding-left: 0; padding-top: $smartdeal-gridunit*2;
          &:before { left: 50%; top: 0; transform: translate(-50%, 0); }
        }
      }

      H4 { margin: 0; font-size: 30px; font-weight: $bold; }
      A {
        color: $color-text-dark;
        &:not(.button) { text-decoration: underline; }
      }
      IMG {
        &.align-right {
          @include not-mobile { float: right; margin: 0 0 $gridunit $gridunit; }
        }
      }
      .smartdeal-steps__illustration {
        box-sizing: border-box; display: flex; justify-content: center; align-items: center; overflow: hidden; margin-right: $gridunit; margin-left: $gridunit;
        IMG { max-width: 100%; display: block }
        &.align-right {
          order: 2; margin-right: $smartdeal-gridunit*-1; flex: 0 0 40%;
          &.padded { padding-right: $gridunit-large; }
        }
        &.align-left {
          margin-left: 0; flex: 0 0 30%;
          &.padded { padding-left: $gridunit-large; }
        }
        &.justify-end { justify-content: flex-end; }
        &.valign-top { margin-top: $smartdeal-gridunit*-1.2; }
        &.valign-bottom { margin-bottom: $smartdeal-gridunit*-1; align-self: flex-end; }
        &.valign-both {
          margin-top: $smartdeal-gridunit*-1.2; margin-bottom: $smartdeal-gridunit*-1.2;
          IMG { height: 100%; width: 100%; object-fit: cover; }
          @include mobile {
            margin-top: 0; margin-bottom: $gridunit-large;
          }
        }
        @include mobile {
          &.align-right { margin-top: $gridunit-large; }
          &.valign-bottom { order: 2; margin-top: $gridunit-large; }
        }
      }
    }
  }
  &-specs {
    margin: $gridunit-large 0 0 ($smartdeal-gridunit+50px)*-1;
    SELECT { font-size: 22px; width: 100%; border: 1px solid $color-gray-medium-light; border-radius: $base-radius; padding-left: $gridunit; padding-top: $gridunit-small; padding-bottom: $gridunit-small; }
    @include mobile {
      margin: $gridunit-large 0 0;
      SELECT { font-size: 18px; padding-left: $gridunit-small; }
    }
  }
  &-periods-table {
    width: 100%; margin: $gridunit 0 0; border-collapse: collapse;
    TBODY {
      &:not(:last-child) {
        TR {
          &:last-child {
            TD, TH { border-bottom: 1px solid currentColor; }
          }
        }
      }
      TR {
        TD, TH { border-bottom: none; padding: 0 !important; }
        &:first-child {
          TD, TH { padding: $gridunit 0 0 !important; }
        }
        &:last-child {
          TD, TH { padding: 0 0 $gridunit !important; }
        }
        TH { text-align: left; }
        TD { text-align: right; }
        &.primary {
          TD, TH { font-size: 24px; font-weight: $bold;}
        }
      }
    }
  }
  &-device-price {
    display: flex; justify-content: space-between; gap: $gridunit-small; overflow: hidden; font-size: 17px; opacity: 0.7;
    SPAN {
      &:first-child { flex: 0 1 100%; white-space: normal; }
      &:last-child { flex: 0 0 auto; white-space: nowrap; }
    }
  }
  &-product {
    &-data {
      display: flex; align-items: flex-start; margin: $smartdeal-gridunit 0 0;
      .af-smartdeal-business & { margin-top: 10px; }
      @include mobile { display: block; overflow: hidden; }
      & &__image {
        flex: 0 0 210px; margin: 0 $smartdeal-gridunit 0 $gridunit; max-width: 210px;
        @include mobile { max-width: 70%; margin: 0 auto; display: block; }
      }
      & &__content {
        @include mobile {
          margin: $gridunit-large 0 0;
        }
      }
      & &__title {
        font-size: 22px; margin: 0; font-weight: $bold;
        + * { margin-top: $gridunit; }
      }
      &__bar {
        display: flex; background-color: #e0e0e0; border-radius: 10px; height: 20px; margin: $smartdeal-gridunit 0 0;
        &-fill {
          flex: 1; font-size: $font-size-smallest; text-align: center; line-height: 20px; border-radius: 10px;
          &.primary { color: $color-text-light; background-color: $color-primary; flex: 0 0 66.666%; }
        }
      }
      TABLE {
        font-size: 18px;
        @include mobile { font-size: 14px; max-width: 100%; }
        TD, TH {
          border-bottom: 1px solid #969696; padding: $gridunit !important; white-space: normal;
          @include mobile {
            padding: $gridunit-small !important;
          }
        }
      }
    }
  }
  &-products-list {
    flex: 0 0 100%;
    display: flex; flex-wrap: wrap; justify-content: center;
    .smartdeal-product {
      flex: 0 0 25%; box-sizing: border-box; padding: $gridunit; position: relative; text-align: center; display: flex; flex-direction: column;
      &:hover {
        &__button { background-color: darken($color-primary, 5%); }
      }
      IMG { height: 170px; object-fit: contain; object-position: bottom center; }
      &__link { position: absolute; left: $gridunit; right: $gridunit; top: $gridunit; bottom: $gridunit; text-indent: -10000px; }
      &__title { font-size: 20px; font-weight: $bold; flex: 0 1 100%; }
      &__button { display: inline-flex; margin: $gridunit 0 0; text-align: center; justify-content: center; border-radius: 20px; padding: $gridunit-small $gridunit; background-color: $color-primary; color: $color-text-light; }
    }
    @include mobile {
      .smartdeal-product { flex: 0 0 100%; }
    }
  }
  &-columns {
    display: flex; margin: $smartdeal-gridunit 0 0; font-size: 18px;
    @include mobile {
      flex-direction: column;
    }
  }
  &-column {
    flex: 1; padding: 0 $gridunit; box-sizing: border-box;
    &.size-narrow { flex: 0 0 33.333%; }
    &.size-wide { flex: 0 0 66.666%; }
    &.align-bottom {
      align-self: flex-end; margin-bottom: $smartdeal-gridunit*-1;
      IMG { display: block; }
    }
    @include mobile {
      flex: 0 0 auto !important; max-width: 100%; overflow: hidden; padding: 0;
      &.size-narrow {
        IMG { max-height: 40vh; width: auto; margin: 0 auto; }
      }
      &.align-bottom {
        order: 2; margin-top: $gridunit-large; align-self: normal;
      }
    }
  }
  &-blocks01 {
    padding-bottom: $smartdeal-gridunit;
    @include mobile { margin: $gridunit-large 0 0; }
    > LI {
      background-color: $color-gray-lighter02; overflow: hidden; border-radius: $base-radius*2; padding: $smartdeal-gridunit*1.2 $smartdeal-gridunit 0; font-size: 18px; line-height: 1.4;
      display: flex;
      @include mobile {
        flex-direction: column;
        .smartdeal-blocks01__illustration { order: 2; }
      }
      &:nth-child(even) .smartdeal-blocks01__illustration { order: 2; margin-right: 0; margin-left: $gridunit-large;}
      + LI { margin: $gridunit 0 0; }
    }
    &.inverted > LI {
      .smartdeal-blocks01__illustration { order: 2; margin-right: 0; margin-left: $gridunit-large;}
      &:nth-child(even) .smartdeal-blocks01__illustration { order: 0; margin-left: 0; margin-right: $gridunit-large;}
    }
    &__illustration {
      flex: 0 0 48%; align-self: flex-end; margin-right: $gridunit-large;
      IMG { display: block; width: 100%; max-width: 300px; margin: 0 auto; }
    }
    &__content {
      padding-bottom: $smartdeal-gridunit; flex: 0 1 100%; font-size: 18px; line-height: 1.4; align-self: center;
      H4 { margin: 0; font-size: 20px; }
    }
  }
  &-embed {
    padding-bottom: 56.25%; position: relative;
    IFRAME { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; }
  }
  &-disclaimer {
    margin: $smartdeal-gridunit*3 0 0; border-top: 1px solid #969696; padding: $gridunit-small 0 $gridunit; color: $color-text-medium; font-size: $font-size-smaller; line-height: 1.4;
    P { margin: $gridunit-small 0 0; }
    @include mobile { margin-top: $smartdeal-gridunit; }
  }
  &-checkbox {
    margin: $gridunit 0 $gridunit-small*-1; background-color: #f3f3f3; padding: 0 $gridunit-small $gridunit-small/2; border-radius: $base-radius;
    .checkbox-with-label-label { font-weight: normal; }
    .checkbox-with-label-label-wrapper { padding-left: $gridunit-small; }
  }
  &-abc {
    padding: 0; display: grid; grid-column-gap: $gridunit-large; grid-template-columns: 1fr 1fr 1fr; margin: $gridunit-large auto 0; max-width: $smartdeal-maxw;
    @include mobile { grid-template-columns: 1fr; }
    > LI {
      background-color: $smartdeal-purple; color: $color-text-light; font-size: 20px; padding: $gridunit-large; border-radius: $smartdeal-radius; text-align: center; line-height: 1.4;
      @include mobile { margin: $gridunit 0 0; }
    }
    &__bullet {
      font-size: 30px; font-weight: $bold; width: 50px; height: 50px; margin: 0 auto $gridunit-large;
      border: 1px solid currentColor; border-radius: 50%; box-sizing: border-box; text-align: center;
      display: flex; align-items: center; justify-content: center;
    }
    &__primary { font-size: 28px; }
  }
  &-content {
    overflow: hidden;
    &.theme01 { background-color: $color-gray-lighter02; }
  }
  &-faq {
    background-color: $color-white; border-radius: $smartdeal-radius; max-width: $smartdeal-maxw; margin: $gridunit auto 0; padding: 0 $gridunit-large*1.5 $gridunit-large*1.5; box-sizing: border-box; overflow: hidden;
    @include mobile { margin: $gridunit $gridunit 0; padding: 0 $gridunit; }
    &__items {
      margin: $gridunit-large*2 0 0;
      & & { margin: $gridunit 0 0 $smartdeal-gridunit; }
    }
    &__item {
      margin: $gridunit-small 0 0; position: relative;
      &:first-child { margin: 0; }
      INPUT[type="checkbox"] {
        position: absolute; left: -10000px;
        + H2, + H3 {
          border-radius: $smartdeal-radius-small; margin: 0;
          LABEL {
            position: relative; padding: $gridunit $gridunit*3+$smartdeal-gridunit $gridunit $gridunit*2; display: block; cursor: pointer;
            @include mobile { padding: $gridunit $gridunit*3+$smartdeal-gridunit $gridunit $gridunit; }
            &:after {
              position: absolute; right: $gridunit*2; top: 50%; transform: translate(0, -50%); content: "+"; font-size: 15px; color: $color-text-light; text-align: center; line-height: $smartdeal-gridunit; font-weight: 400;
              width: $smartdeal-gridunit; height: $smartdeal-gridunit; border-radius: 50%; background-color: #0071e3;
              &:hover {
                background-color: darken(#0071e3, 10%);
              }
            }
          }
        }
        + H2 { background-color: #f5f5f7; }
        + H3 {
          background-color: #fafafb;
        }
        &:checked {
          + H2, + H3 {
            LABEL:after { content: "–"}
          }
          + H3 {
            border-radius: $smartdeal-radius-small $smartdeal-radius-small 0 0;
            + .smartdeal-faq__item-content {
              background-color: #fafafb; border-radius: 0 0 $smartdeal-radius-small $smartdeal-radius-small; overflow: hidden; padding: 0 $smartdeal-gridunit $gridunit;
              font-size:  20px;
            }
          }
          ~ .smartdeal-faq__item-content {
            display: block;
          }

        }
      }
      &-content { display: none; }
    }
  }
}

.af-smartdeal-business {
  .smartdeal-product-data {
    margin-bottom: $gridunit-large;
    .smartdeal-product-data__image {
      flex: 0 0 33%; margin: 0 $smartdeal-gridunit 0 $gridunit; max-width: 33%;
      @include mobile { max-width: 70%; margin: 0 auto; display: block; }
    }
  }
  #backdrop { overscroll-behavior: contain; }
  #modal { overscroll-behavior: contain; }
  .sdb .modal-content {
    overflow-y: auto; overflow-x: hidden; padding-right: 5px; overscroll-behavior: contain;
    &::-webkit-scrollbar { -webkit-appearance: none; width: 8px; }
    &::-webkit-scrollbar-thumb { background-color: rgba(255,255,255,0.2); border-radius: 4px; border: 1px solid transparent; }
    &::-webkit-scrollbar-track { background: rgba(255,255,255,0.05); }
  }
  .webforms {
    FIELDSET { border: none; padding: 0; margin: 0; }
    @include not-mobile {
      &,
      &-success-text { margin-left: 33.333%; }
    }
    .field {
      margin: $gridunit 0 0;
      @include not-mobile { display: flex; align-items: center; gap: $gridunit-small; }
      .label { font-size: $font-size-medium; flex: 0 0 200px; padding-top: 5px; }
      .control { margin-top: $gridunit-small/2; flex: 0 1 100%; }
      INPUT, SELECT, TEXTAREA {
        background: #0f0f10; border: 1px solid #313132; color: #fff; border-radius: $base-radius; padding: $gridunit-small $gridunit;
        &:focus { border-color: #666; }
      }
    }
    .actions-toolbar {
      padding: 0 $gridunit*1.3 0 $gridunit-small;

      .primary { max-width: 100%; overflow: hidden;}
    }
    .action { width: 100%; border-radius: 7px; box-sizing: border-box; }
  }
  .product__submit {
    display: block; margin: $gridunit auto 0;
    -webkit-transition: color .25s ease-in-out,background-color .25s ease-in-out;
    -moz-transition: color .25s ease-in-out,background-color .25s ease-in-out;
    transition: color .25s ease-in-out,background-color .25s ease-in-out;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: none;
    color: #fff;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
    padding: $gridunit-small/2 $gridunit;
    position: relative;
    vertical-align: middle;
    line-height: 40px;
    background-color: #1875f0;
    font-size: $font-size-medium;
    font-weight: 500;
    text-transform: uppercase;
    &:hover { background-color: rgba(#1875f0, 0.7); }
  }
}

.bg-black {
  .smartdeal-steps__item {
    background-color: transparent; padding: 0;
    @include mobile { padding: 0 $gridunit; }
    &-inner {
      padding-left: 0;
      &:before { display: none; }
      > H4:first-child { display: none; }
      .smartdeal-specs {
        margin-left: 0;
        SELECT {
          appearance: none; background: rgba($color-white, 0.2) url('../img/select-arrow-white.png') 100% 50% no-repeat; background-size: auto 16px; border: none !important; outline: none !important; box-shadow: none !important;
          font-size: $base-font-size;
          &:focus { border-color: transparent; }
          @include not-mobile { margin-left: calc(40% + 55px); width: calc(60% - 55px); }
        }
      }
      .smartdeal-button { border-radius: 3em; padding: $gridunit; }
      .smartdeal-product-data {
        TABLE {
          font-size: $base-font-size;
          TD {
            padding: $gridunit-small !important;
            &:first-child { padding-left: 0 !important; }
            &:last-child { padding-right: 0 !important; text-align: right; }
          }
        }
        .smartdeal-product-data__image {
          flex: 0 0 40%; max-width: 40%;
          max-height: 400px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
