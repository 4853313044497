@import "mixins";
@import "variables";

// Forms

.form-container {
	display: block; @include defaultMargin(); padding: 0 $gridunit $gridunit $gridunit; border-radius: $base-radius; border: 1px solid $color-gray-lighter;
	H1, H2, H3, H4, H5, H6 {
		&:first-child { margin-top: $gridunit; }
	}
	FIELDSET { border: none; padding: 0; margin: 0; }
}

.form-list {
	padding: 0; margin: 0 $gridunit / -2; line-height: $gridunit; overflow: hidden; display: flex; flex-direction: row; flex-wrap: wrap;
	> LI {
		flex: 1 1 100%; padding: 0 $gridunit / 2; margin: $gridunit 0 0 0; position: relative; overflow: hidden; box-sizing: border-box;
		&.split { flex: 1 1 50%; min-width: 250px; }
	}

	$h: 40px;

	LABEL {
		display: inline-block; padding: 0 0 5px 0; line-height: inherit;
		EM { float: right; color: $color-danger; margin-left: 5px; }
	}

	INPUT, SELECT, TEXTAREA {
		border: 0 none; box-shadow: none; appearance: none; outline: none; font-size: 16px;
		margin: 0; width: 100%; background-color: rgba($color-black, 0.05); height: $h; line-height: $h; border-radius: $base-radius;
		&:focus { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
	}

	INPUT {
		padding: 0 10px;
		&:not(:last-child) { padding-right: 50px; }
		&[type="checkbox"] {
			position: absolute; right: 100%; bottom: 100%;
			+ LABEL {
				position: relative; padding: 0 0 0 $gridunit + 10px; display: inline-block;
				&:before, &:after { transition: all $uispeed $uieasing 0ms; }
				&:before { content: ""; width: $gridunit; height: $gridunit; position: absolute; left: 0; top: 0; background-color: rgba($color-black, 0.05); border-radius: $base-radius; }
				&:after { content: ""; width: 10px; height: 10px; position: absolute; left: 5px; top: 5px; background-color: $color-link; border-radius: 3px; opacity: 0; }
			}
			&:checked + LABEL {
				&:after { opacity: 1; }
			}
			&:focus + LABEL {
				&:before { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
			}
		}
		&[type="file"] {
			line-height: 30px; padding: 5px; height: auto;
		}
	}

	SELECT {
		padding: 0 30px 0 10px; background: rgba($color-black, 0.05) url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
		&::-ms-expand {
			display: none;
		}
		.bg-black & { background: rgba($color-white, 0.2) url('img/select-arrow-white.png') 100% 50% no-repeat; background-size: auto 16px; }
	}

	TEXTAREA { line-height: $gridunit; padding: 10px; height: 150px; }

	.dropzone { display: block; width: 100%; }
}

.form-toggler {
	position: relative; overflow: hidden; margin: $gridunit 0 0 0; line-height: $gridunit;
	>  INPUT[type="checkbox"] {
		position: absolute; right: 100%; bottom: 100%;
		+ LABEL {
			position: relative; padding: 0 0 0 $gridunit + 10px; display: block; line-height: inherit;
			&:before, &:after { transition: all $uispeed $uieasing 0ms; }
			&:before { content: ""; width: $gridunit; height: $gridunit; position: absolute; left: 0; top: 0; background-color: rgba($color-black, 0.05); border-radius: $base-radius; }
			&:after { content: ""; width: 10px; height: 10px; position: absolute; left: 5px; top: 5px; background-color: $color-link; border-radius: 3px; opacity: 0; }
		}
		&:checked + LABEL {
			&:after { opacity: 1; }
			& ~ * { display: block; }
		}
		&:focus + LABEL {
			&:before { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
		}
	}

	&__content { display: none; margin-bottom: $gridunit; border-bottom: 1px solid $color-gray-light; padding-bottom: $gridunit; }
}

.form-buttons {
	$h: 40px;
	display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: flex-start; margin: 10px -10px 0 0;
	> LI { flex: 0 0 auto; margin: 10px 10px 0 0; }
	&__button {
		display: block; padding: 0 $gridunit-large; line-height: $h; height: $h; background-color: $color-white; box-shadow: inset 0 0 0 1px $color-link; white-space: nowrap; border: none; border-radius: $h / 2; cursor: pointer; box-sizing: border-box; text-align: center; outline: none;
		&.intent-primary {
			background-color: $color-link; color: $color-white; font-weight: $bold;
			&:hover { background-color: $color-link-hover; text-decoration: none; }
		}
		&:focus { box-shadow: inset 0 0 0 1px $color-link, 0 0 0 3px rgba($color-link, 0.2); }

		&[disabled],
		&.disabled { opacity: 0.5; pointer-events: none; }
	}
	&__social {
		display: block; padding: 0 $gridunit 0 40px; line-height: $h; height: $h; background-color: $color-white; box-shadow: inset 0 0 0 1px $color-link; white-space: nowrap; border: none; border-radius: $h / 2; cursor: pointer; box-sizing: border-box; text-align: center; outline: none;
		&.intent-primary {
			background-color: $color-link; color: $color-white; font-weight: $bold;
			&:hover { background-color: $color-link-hover; text-decoration: none; }
		}
		&:focus { box-shadow: inset 0 0 0 1px $color-link, 0 0 0 3px rgba($color-link, 0.2); }
		&.facebook { background: rgb(24, 119, 242) url(img/icon-facebook.svg) 10px 10px no-repeat; background-size: 20px 20px; color: $color-white; }
		&.google { background: $color-white url(img/icon-google.svg) 10px 10px no-repeat; background-size: 20px 20px; }
	}

	@include mobile {
		flex-direction: column; align-items: stretch; justify-content: stretch;
		&__button { width: 100%; }
	}
}

.button {
	IMG { display: block; }
}
.radio-group {
	margin: $gridunit 0 0;
	&__tabs {
		padding: 0; margin: 0; border: none; display: flex; position: relative; top: 1px; z-index: 2;
		LABEL {
			position: relative; display: flex; margin-right: 2px; cursor: pointer;
			&:hover SPAN { background-color: transparentize($color-gray-light, .85); }
		}
		INPUT {
			position: absolute; left: -10000px;
			&:checked + SPAN { border-bottom-color: $color-white; }
		}
		SPAN {
			padding: $gridunit/1.2 $gridunit; transition: background-color $uispeed; display: flex; align-items: center; border: 1px solid $color-gray-light; text-align: center;
			@include mobile { padding: $gridunit-small; }
		}
	}
	&__content {
		padding: $gridunit; border: 1px solid $color-gray-light;
	}
}

.frame-ideal {
	.control-switch {
		INPUT:checked + SPAN:after { background-color: $color02; }
	}
}

FIELDSET.fieldset {
	padding: 0; margin: 0 $gridunit / -2; line-height: $gridunit; overflow: hidden; display: flex; flex-direction: row; flex-wrap: wrap;
	> .field {
		flex: 1 1 100%; padding: 0 $gridunit / 2; margin: $gridunit 0 0 0; position: relative; overflow: hidden; box-sizing: border-box;
		&.split { flex: 1 1 50%; min-width: 250px; }
	}

	$h: 40px;

	LABEL {
		display: inline-block; padding: 0 0 5px 0; line-height: inherit;
		EM { float: right; color: $color-danger; margin-left: 5px; }
	}

	INPUT, SELECT, TEXTAREA {
		border: 0 none; box-shadow: none; appearance: none; outline: none; font-size: 16px;
		margin: 0; width: 100%; background-color: rgba($color-black, 0.05); height: $h; line-height: $h; border-radius: $base-radius;
		&:focus { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
	}

	INPUT {
		padding: 0 10px;
		&:not(:last-child) { padding-right: 50px; }
		&[type="checkbox"] {
			position: absolute; right: 100%; bottom: 100%;
			+ LABEL {
				position: relative; padding: 0 0 0 $gridunit + 10px; display: inline-block;
				&:before, &:after { transition: all $uispeed $uieasing 0ms; }
				&:before { content: ""; width: $gridunit; height: $gridunit; position: absolute; left: 0; top: 0; background-color: rgba($color-black, 0.05); border-radius: $base-radius; }
				&:after { content: ""; width: 10px; height: 10px; position: absolute; left: 5px; top: 5px; background-color: $color-link; border-radius: 3px; opacity: 0; }
			}
			&:checked + LABEL {
				&:after { opacity: 1; }
			}
			&:focus + LABEL {
				&:before { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
			}
		}
		&[type="file"] {
			line-height: 30px; padding: 5px; height: auto;
		}
	}

	SELECT {
		padding: 0 30px 0 10px; background: rgba($color-black, 0.05) url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
		&::-ms-expand {
			display: none;
		}
	}

	TEXTAREA { line-height: $gridunit; padding: 10px; height: 150px; }

	.dropzone { display: block; width: 100%; }
}

.actions-toolbar {
	$h: 40px;
	.primary {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: flex-start; margin: 10px -10px 0 0;
		.action {
			flex: 0 0 auto; margin: 10px 10px 0 0;
			display: block; padding: 0 $gridunit-large; line-height: $h; height: $h; background-color: $color-white; box-shadow: inset 0 0 0 1px $color-link; white-space: nowrap; border: none; border-radius: $h / 2; cursor: pointer; box-sizing: border-box; text-align: center; outline: none;
			&.primary {
				background-color: $color-link; color: $color-white; font-weight: $bold;
				&:hover { background-color: $color-link-hover; text-decoration: none; }
			}
			&:focus { box-shadow: inset 0 0 0 1px $color-link, 0 0 0 3px rgba($color-link, 0.2); }

			&[disabled],
			&.disabled { opacity: 0.5; pointer-events: none; }
		}
		@include mobile {
			flex-direction: column; align-items: stretch; justify-content: stretch;
		}
	}
}

.layout-form { text-align: left; }





