@import 'variables';

// Responsive sets

@mixin tiny { @media only screen and (max-width: 239px) { @content; } }
@mixin mobile { @media only screen and (max-width: 759px) { @content; } }
@mixin mobile-narrow { @media only screen and (max-width: 449px) { @content; } }
@mixin mobile-wide { @media only screen and (min-width: 450px) and (max-width: 759px) { @content; } }
@mixin not-mobile { @media only screen and (min-width: 760px) { @content; } }
@mixin tablet { @media only screen and (min-width: 760px) and (max-width: 1023px) { @content; } }
@mixin desktop { @media only screen and (min-width: 1024px) { @content; } }
@mixin not-desktop { @media only screen and (max-width: 1023px) { @content; } }
@mixin desktop-wide { @media only screen and (min-width: 1281px) { @content; } }
@mixin desktop-very-wide { @media only screen and (min-width: 1440px) { @content; } }
@mixin desktop-ultra-wide { @media only screen and (min-width: 1920px) { @content; } }
@mixin not-desktop-wide { @media only screen and (max-width: 1280px) { @content; } }

//

@mixin defaultPadding {
	@include mobile { padding: $base-padding-mobile; }
	@include tablet { padding: $base-padding-tablet; }
	@include desktop { padding: $base-padding-desktop; }
}
@mixin defaultPaddingTop($offset: 0) {
	@include mobile { padding-top: $spacer-mobile-vertical - $offset; }
	@include tablet { padding-top: $spacer-tablet-vertical - $offset; }
	@include desktop { padding-top: $spacer-desktop-vertical - $offset; }
}

@mixin defaultMargin($offset: 0) {
	@include mobile { margin-top: $spacer-mobile-vertical - $offset; }
	@include tablet { margin-top: $spacer-tablet-vertical - $offset; }
	@include desktop { margin-top: $spacer-desktop-vertical - $offset; }
}

// Clearing

@mixin clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// Scrolling

@mixin overflow($displayScrollbars: false) {
	overflow: hidden;
	@if $displayScrollbars == true {
		overflow-y: scroll;
	} @else {
		overflow-y: auto;
	}
	-ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto;
}
