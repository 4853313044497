@import "mixins";
@import "variables";

// Browse layout

.layout-browse {
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: nowrap;
		&__sidebar { flex: 0 0 280px; overflow: hidden; padding-right: $gridunit; margin-right: -1px; border-right: 1px solid $color-gray-lighter; }
		&__main { flex: 1 1 auto; }
	}
	@include mobile {
		&__main { display: flex; flex-direction: column; }
	}
}

// Grid layout for promotions

.layout-grid {
	flex: 1 0 auto;
	@include mobile {
		margin: 0 -20px;
		&__container { padding: 12px 0 0 0; }
	}
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 12px; margin: 0 -12px 0 -12px;
		&__container {
			flex: 0 0 50%; max-width: 50%; box-sizing: border-box; padding: 12px 12px 0 0; display: flex; flex-direction: column;
			&.wide { flex: 0 0 100%; max-width: 100%; }
		}
	}
	@include defaultMargin(12px);
}

// Products layout

.layout-products {
	&__list {
		flex: 1 0 auto; @include defaultMargin(12px);

		@include mobile { margin: 0 -20px; }
		@include not-mobile { display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 12px; margin: 0 -12px 0 -12px; }

		&.light {
			display: flex; flex-direction: row; flex-wrap: wrap;

			@include mobile {
				margin: 0 -20px 0 -21px;
				.box-product { padding: 15px; font-size: 14px; }
			}
			@include not-mobile {
				margin: 0; padding: 0; overflow: hidden;
			}
		}

		&.list { display: block; }

		&.standalone { margin-top: 18px; }
	}

	&__container {
		@include mobile {
			padding: 12px 0 0 0;
		}
		@include not-mobile {
			flex: 0 0 33.333%; max-width: 33.333%; box-sizing: border-box; padding: 12px 12px 0 0; display: flex; flex-direction: column;
			&.wide { flex: 0 0 66.666%; max-width: 66.666%; }
			.react-multi-carousel-item & {
				flex: 0 0 100%; max-width: 100%;
				padding: 0 6px 0;
			}
		}
	}

	&__list.light &__container {
		border: solid $color-gray-lighter; border-width: 0 0 1px 1px; box-sizing: border-box; padding: 0;
		@include mobile {
			flex: 0 0 50%; max-width: 50%; padding: 0;
		}
		@include not-desktop-wide {
			flex: 0 0 50%; max-width: 50%;
		}
	}

	&__list.list &__container { max-width: 100%; padding: 0; }
}

// Single product layout

.layout-product {
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: nowrap;

		&__image { flex: 1 1 50%; margin-right: 40px; font-size: 12px; line-height: 1.5; }
		&__content { flex: 1 1 50%; }
	}

	H1 { font-size: 32px; }

	&__image {
		position: relative;
		.product-sale { top: $gridunit-large; transform: scale(1.5); transform-origin: 100% 0; }
	}
	&__code { margin: 0 !important; opacity: 0.5; font-size: $font-size-small; }
}

// Login layout

.layout-login {
	flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; margin: 0 -10px;
	@include not-mobile {
		display: flex; flex-direction: row; flex-wrap: nowrap;
	}
	&__column {
		flex: 1 1 50%; box-sizing: border-box; box-shadow: inset 0 0 0 1px $color-gray-light; @include defaultPadding; @include defaultMargin(); margin-left: 10px; margin-right: 10px; border-radius: $base-radius;
		&.secondary { background-color: $color-gray-lightest; box-shadow: none; }
		H2 { @include defaultMargin(); }
		H3 { border-bottom: 1px solid $color-gray-light; padding-bottom: 5px; margin-top: $gridunit-large; }

		.tabbed-content{
			&__panel {
				@include desktop {
					margin-left: -$gridunit-large; margin-right: -$gridunit-large; border-style: solid none none;
				}
				@include not-desktop {
					margin-left: -$gridunit; margin-right: -$gridunit;
				}
			}
			@include not-desktop { margin-top: $gridunit-small; }
		}

	}
}

// Stores layout

.layout-stores {
	display: flex; flex-direction: row; flex-wrap: wrap; font-size: 14px; line-height: 1.5; margin: 0 -8px 0 0;
	> LI {
		flex: 0 0 50%; max-width: 50%; box-sizing: border-box; padding: $gridunit + 8px $gridunit + 8px $gridunit $gridunit; position: relative; z-index: 0;
		&:before { content: ""; position: absolute; left: 0; right: 8px; top: 8px; bottom: 0; background-color: $color-gray-lightest; z-index: -1; border-radius: $base-radius; }
		@include mobile {
			flex: 0 0 100%; max-width: 100%;
		}
	}
	H1, H2, H3, H4, H5 { margin-top: $gridunit; font-size: 18px; }
	P {
		margin: 10px 0 0 0;
		&.description { color: $color-gray-medium; margin: 5px 0 $gridunit / 2 0; }
	}
	A {
		color: inherit;
		&:hover { color: $color-link; }
	}
	.image { padding: 60% 0 0 0; display: block; background: 50% 50% no-repeat; background-size: cover; margin: $gridunit * -1 $gridunit * -1 0 $gridunit * -1; border-radius: $base-radius $base-radius 0 0; }
}

// Contacts layout

.layout-contacts {
	display: flex; flex-direction: row; flex-wrap: wrap; font-size: 14px; line-height: 1.5; margin: 0 -20px; text-align: center;

	&.centered { justify-content: center; }
	&.in-3-columns > LI {
		@include desktop {
			flex: 0 0 33.333%; max-width: 33.333%;
		}
	}

	> LI {
		flex: 0 0 25%; max-width: 25%; box-sizing: border-box; padding: $gridunit * 2 $gridunit 0 $gridunit; position: relative;
		@include not-desktop { flex: 0 0 50%; max-width: 50%; }
		@include mobile { flex: 0 0 100%; max-width: 100%; }
		@include mobile-wide { flex: 0 0 50%; max-width: 50%; }
	}
	H1, H2, H3, H4, H5 { margin-top: $gridunit; font-size: 18px; }
	P {
		margin: 10px 0 0 0;
		&.description { color: $color-gray-medium; margin: 0 0 $gridunit / 2 0; }
	}
	A {
		color: inherit;
		&:hover { color: $color-link; }
	}
	.image {
		display: block; background: 50% 50% no-repeat; background-size: cover; margin: 0 auto; border-radius: 50%; width: 200px; height: 200px;
		@include mobile-wide { width: 150px; height: 150px; }
	}
	.logo {
		display: block; background: 50% 50% no-repeat; background-size: contain; margin: 0 auto; width: 200px; height: 100px;
		@include mobile-wide { width: 200px; height: 100px; }
	}
}

// Shopping cart

.layout-cart {
	@include desktop {
		display: flex; flex-direction: row-reverse; flex-wrap: nowrap;
		&__sidebar { flex: 0 0 280px; overflow: hidden; padding-left: $gridunit; border-left: 1px solid $color-gray-lighter; margin-left: $gridunit; }
		&__main { flex: 1 1 auto; }
	}
}

// Comparison

.layout-compare {
	display: flex; flex-direction: row; flex-wrap: nowrap; margin: 0 -8px; font-size: 17px; line-height: 1.5;

	&__column {
		flex: 1 1 33.333%; max-width: 33.333%; box-sizing: border-box; padding: 0 8px;
		@include mobile {
			flex: 1 1 50%; max-width: 50%;
			&:nth-child(3) { display: none; }
		}
	}

	SELECT {
		$h: 50px;
		border: 0 none; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); font-size: inherit; appearance: none; outline: none; width: 100%; font-weight: $bold;
		padding: 0 30px 0 $gridunit; height: $h; line-height: $h; border-radius: $base-radius; margin: 0 7px 0 0;
		background: $color-white url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
		&::-ms-expand {
			display: none;
		}
	}
	P { margin-top: 30px; }

	.box-product {
		background-color: transparent; padding: 0; margin: 30px 0 0 0;
	}
	.box-product__swatches { margin-top: 30px; align-items: flex-start; justify-content: flex-start; }
}

// Generic address columns

.layout-addresses {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 -8px;
	> LI {
		flex: 0 0 33.333%; max-width: 33.333%; padding: 0 8px; box-sizing: border-box;
		@include not-desktop { flex: 0 0 50%; max-width: 50%; }
	}
	P + P { margin-top: 10px; }
}

// Smartdeal

.layout-smartdeal {
	H1 { text-align: center; max-width: 620px; margin: $gridunit-large*1.5 auto 0; padding: 0 $gridunit; font-weight: $bold; line-height: 1.4; font-size: $font-size-largest02; }
	H2 {
		font-size: 30px; font-weight: $bold;
		IMG { display: block; margin: 0 auto .5em; }
		&.align-center { text-align: center; }
	}
	UL:not([class]) {
		> LI {
			margin: 0; padding-left: 30px;
			&:before { left: 10px; top: .4em; }
		}
	}
	IMG { max-width: 100%; height: auto; }
}

// News
.layout-news {
	&__main {
		IMG { max-width: 100%; height: auto; }
	}
	@include not-mobile {
		display: flex;
		&__main {
			flex: 1 1 auto; max-width: calc(100% - 240px - #{$gridunit*4});
		}
		&__sidebar {
			flex: 0 0 240px; margin-left: $gridunit*4;
			H2 {
				font-weight: $bold;
				&:first-child { margin-top: $gridunit-small; }
			}
		}
	}
}
